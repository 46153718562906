/*
 * jQuery UI Autocomplete Multiselect Widget
 *
 * developed by FZambia, 2011
 *
 * thanks jQuery UI developers for their great widget library:
 * http://docs.jquery.com/
 *
 * Depends:
 *     jquery.ui.autocomplete.js
 */

(function($) {
	var autoselect = {
	    options:{
	        source:'/search/autocomplete/',
	        prefix:'user',
	        value:'',
	        delay:300,
	        minLength:2,
	        help:'начните ввод...',
	        initial:false,
			user: false,
            selected:function(){},
            close_callback:function(){}
	    },
	    _create:function(){
	        this._render();
	    },
	    _render:function(){
	        var e = this.element;
	        var o = this.options;
	        this._create_html();
	        var self = this;
	        e.autocomplete({
	            source: o.source,
	            minLength: o.minLength,
	            delay:o.delay,
	            select: function(event, ui) {
	                this.value='';
					var tag;
					if(ui.item.appointment){
						tag = self._make_tag(ui.item.value, ui.item.id);
					}else{
						tag = self._make_tag(ui.item.value);
					}
	                self._add_tag(tag, ui.item.id);
                    o.selected(event, ui);
	                return false;
	            },
                focus: function(event, ui) {
                    $('.ui-menu-item').removeClass('autocomplete-suggest-selected');
                    $('#autocomplete-item-' + ui.item.id).addClass('autocomplete-suggest-selected');
                },
				response: function (event, ui) {
                    if (ui.content.length !== 0) {
                        if (!Array.isArray(ui.content[0].value)) {
                            return;
                        }
                    }
					var resultContent = [];
					$.each(ui.content, function (groupIndex) {
						$.each(ui.content[groupIndex].value, function (itemIndex) {
							resultContent.push(ui.content[groupIndex].value[itemIndex]);
						});
					});
					$.each(resultContent, function (index) {
						ui.content[index] = resultContent[index]
					})
                }
	        }).keypress(function(e) {
	            if (e.keyCode === 13) {
	                return false;
	            }
	        });
	        e.data('ui-autocomplete')._renderItem = function( ul, item ) {
                var menu_item = $( "<li class='ui-menu-item' role='presentation' id='autocomplete-item-" + item.id + "'>" );
                var item_container = $("<a class='autocomplete-suggest-container'>")
                if (item.photo_square) {
                    var template = "<img class='autocomplete-suggest-image' src='" + item.photo_square + "'/>" +
						"<div class='autocomplete-suggest-info'>" +
						"<div class='suggest-username'>" + item.label + "</div>" +
						"<div class='suggest-email'>" + item.email + "</div>" +
						"<div class='suggest-appointment'>" + item.appointment + "</div>";

					if (item.phone) {
						template += "<div class='suggest-appointment'>" + item.phone + "</div>";
					}
					template += "</div>";
					item_container.append(
						template
                    )
                } else {
                    item_container.append("<span>" + item.label + "</span>");
                }
                item_container.appendTo(menu_item);
                return menu_item.appendTo(ul);
            };
	    },
	    
	    _add_tag: function(tag, id) {
	    	this.element.val(id);
            this.element.change();
	    	this.element.parents('.ui-auto-select-search:first').hide();
	    	this.element.parents('.ui-auto-select-choices:first').append(tag);
	    },

	    _remove_tag: function() {
	    	this.element.val('');
            this.element.change();
	    	this.element.parents('.ui-auto-select-choices:first').find('.ui-auto-select-choice:first').remove();
	    	this.element.parents('.ui-auto-select-search:first').show();
            this.options.close_callback();
	    },
	    
		_make_tag:function(value, id) {
			var self = this;
			var item = '';
			if(id){
				item += '<a style="text-decoration: none; color: #333333" target="_blank" href="/users/id/' + id + '">';
			}
			item += '<li class="ui-auto-select-choice">';
			item += '<span>'+ value +'</span>';
			item += '<a rel="1" class="ui-auto-select-choice-close" href="javascript:void(0)"></a>';
			item += '</li>';
			if(id){
				item += '</a>';
			}
			var tag = $(item);
			tag.find('.ui-auto-select-choice-close').bind('click',function(){
				self._remove_tag();
			});
			return tag;
		},
		
	    _create_html:function() {
	        this.wrapper = $('<div class="ui-auto-select-container"></div>'); 
	        this.container = $('<ul class="ui-auto-select-choices field_'+ this.options.prefix +'" style="padding-right:5px;"></ul>');
	        this.search_container = $('<li class="ui-auto-select-search"></li>');
	        this.choice_container = $('<li class="ui-auto-select-choice"></li>');
            
            this.element.attr('placeholder',this.options.help);
            this.element.wrap(this.wrapper)
            this.element.wrap(this.container)
            this.element.wrap(this.search_container)
            if (this.options.initial!==false) {
            	var i = this.options.initial;
            	var tag;
				if (this.options.user){
					tag = this._make_tag(i.value, i.id);
				}else{
					tag = this._make_tag(i.value);
				}
            	this._add_tag(tag, i.id)
            }
	        return true;
	    }
	};
	
	$.widget("ui.autoselect",autoselect);
	
}(jQuery));
