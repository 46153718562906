/*
 * jQuery UI Autocomplete Multiselect Widget
 *
 * developed by FZambia, 2011
 *
 * thanks jQuery UI developers for their great widget library:
 * http://docs.jquery.com/
 *
 * Depends:
 *     jquery.ui.autocomplete.js
 */

(function ($) {
    automultiselect = {
        options: {
            source: '/',
            prefix: 'user',
            value: '',
            delay: 300,
            minLength: 2,
            help: 'начните ввод...',
            help_before: true,
            initial: {},
            style: 'default',
            max: undefined
        },
        _create: function () {
            this._render();
        },
        _render: function () {
            var e = this.element;
            var o = this.options;
            $(e).addClass('multiselect-container');
            var input_elem = $('<input>').addClass('multiselect-input');
            $(this.element).append($(input_elem));
            this._init();
            $(e).click(function () {
                $(input_elem).focus();
            });
            var self = this;
            input_elem.autocomplete({
                source: o.source,
                minLength: o.minLength,
                delay: o.delay,
                select: function (event, ui) {
                    $(input_elem).val('');
                    self._add_item(ui.item.id, ui.item.value, ui.item.type);
                    $('.multiselect-item').fadeIn('slow');
                    return false;
                },
                focus: function (event, ui) {
                    $('.ui-menu-item').removeClass('autocomplete-suggest-selected');
                    $('#autocomplete-item-' + ui.item.id).addClass('autocomplete-suggest-selected');
                },
                response: function (event, ui) {
                    if (ui.content.length == 0) {
                        $(event.target).addClass('red-message');

                        if ($("#automulticomplete__error")[0] === undefined) {
                            $('.form_errors').append('<div id="automulticomplete__error">Некорректные данные<div>');
                        }
                    }
                    else {
                        if (ui.content.length !== 0) {
                            if (!Array.isArray(ui.content[0].value)) {
                                return;
                            }
                        }
                        var resultContent = [];
                        $.each(ui.content, function (groupIndex) {
                            resultContent.push({ value: ui.content[groupIndex].label, type: 'label' });
                            $.each(ui.content[groupIndex].value, function (itemIndex) {
                                resultContent.push(ui.content[groupIndex].value[itemIndex]);
                            });
                        });
                        $.each(resultContent, function (index) {
                            ui.content[index] = resultContent[index]
                        })
                        $(event.target).removeClass('red-message');
                        $("#automulticomplete__error").remove();
                    }
                }
            }).keypress(function (e) {
                if (e.target.value.length <= o.minLength) {
                    $(e.target).removeClass('red-message');
                }
                if (e.keyCode === 13) {
                    return false;
                }
            });

            input_elem.data('ui-autocomplete')._renderItem = function (ul, item) {
                if (item.type === 'label') {
                    return $("<li class='select-label'>" + item.value + "</li>").appendTo(ul);
                } else if (item.type === 'unit') {
                    var menu_item = $("<li class='ui-menu-item' role='presentation' id='autocomplete-item-" + item.id + "'>");
                    var item_container = $("<a class='autocomplete-suggest-container'>");
                    item_container.append(
                        "<div class='group-icon'><i class='fa fa-users' aria-hidden='true'></i></div>" +
                        "<div class='autocomplete-suggest-info'>" +
                        "<div class='suggest-unit'>" + item.value + "</div>" +
                        "<div class='suggest-unit-parent'>" + item.parent + "</div>" +
                        "</div>"
                    );
                } else {
                    var menu_item = $("<li class='ui-menu-item' role='presentation' id='autocomplete-item-" + item.id + "'>");
                    var item_container = $("<a class='autocomplete-suggest-container'>");
                    if (item.photo_square) {
                        item_container.append(
                            "<img class='autocomplete-suggest-image' src='" + item.photo_square + "'/>" +
                            "<div class='autocomplete-suggest-info'>" +
                            "<div class='suggest-username'>" + item.label + "</div>" +
                            "<div class='suggest-email'>" + item.email + "</div>" +
                            "<div class='suggest-appointment'>" + item.appointment + "</div>" +
                            "</div>"
                        );
                    } else {
                        item_container.append("<span>" + item.label + "</span>");
                    }
                }
                item_container.appendTo(menu_item);
                return menu_item.appendTo(ul);
            };
        },
        _add_item: function (id, value, type) {
            if (type === 'user' || typeof type === 'undefined') {
                if (this._data[id] === undefined) {
                    this._data[id] = value;
                    var elem_item = this._make_item(id, value, this.options.prefix, type);
                    $(elem_item).insertBefore($(this.element).children('.multiselect-input'));
                    var self = this;
                    $(elem_item).children('.remove-button').click(function () {
                        var parent = $(this).parent();
                        var remove_id = $(parent).attr('id').replace(self.options.prefix + '-item-', '');
                        delete self._data[remove_id];
                        $(parent).remove();
                        self._rebuild_values();
                    });
                    this._rebuild_values();
                }
            } else if (type === 'unit') {
                var self = this;
                $.ajax({
                    url: '/we/get_users_by_unit/' + id + '/',
                    method: 'GET',
                    success: function (users) {
                        $.each(users, function (i, user) {
                            self._add_item(user.id, user.value, 'user');
                            $('.multiselect-item').fadeIn('slow');
                        });
                    },
                });
            }
        },
        _rebuild_values: function () {
            var values = [];
            $.each(this._data, function (k, v) {
                values.push(k);
            });
            $('#hidden-' + this.options.prefix).val(values.join(',')).trigger('change');
        },
        _init: function () {
            this._data = {};
            var self = this;
            $('.' + self.options.prefix + '-multiselect-item').remove();
            $.each(self.options.initial, function (k, v) {
                self._add_item.apply(self, [k, v, 'user']);
                $('.multiselect-item').css('display', 'inline-block');
            });
        },
        _make_item: function (id, value, prefix) {
            var self = this;

            var text = '';
            if (typeof value == 'string')  // Для обратной совместимости
                text = self._htmlEscape(value);
            else {
                if (value.url)
                    text = '<a target="_blank" href="' + self._htmlEscape(value.url) + '">' + self._htmlEscape(value.name) + '</a>';
                else
                    text = self._htmlEscape(value.name);
            }
            return $('<div>').css('display', 'none').addClass('multiselect-item').addClass(prefix + '-multiselect-item').attr('id', prefix + '-item-' + id).html(text)
                .append($('<span>').addClass('remove-button').css({'padding': '0 4px', 'cursor': 'pointer'}).addClass('fa fa-times'));
        },
        _htmlEscape: function (value) {
            return $('<div>').text(value).html();
        },
        add_new_item: function(id, value) {
            var self = this;
            self._add_item.apply(self, [id, value]);
        },
    };

    $.widget('ui.automultiselect', automultiselect);

}(jQuery));
